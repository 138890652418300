export enum DATE_TIME_FORMATS {
  MMDDYYYY = 'MM/DD/YYYY',
  DDMMYYYY = 'DD/MM/YYYY',
  YYYYMMDD = 'YYYY/MM/DD',
  TIME_12H = 'hh:mm A',
  TIME_12H_SECONDS = 'hh:mm:ss A',
  TIME_24H = 'HH:mm',
  TIME_24H_SECONDS = 'HH:mm:ss'
}

export enum LOCALES {
  ENGLISH = 'en',
  ENGLISH_CA = 'en-CA',
  ENGLISH_GB = 'en-GB',
  ENGLISH_US = 'en-US',
  FRENCH = 'fr',
  FRENCH_CA = 'fr-CA',
  FRENCH_FRANCE = 'fr-FR',
  GERMAN = 'de',
  GERMAN_GERMANY = 'de-DE',
  ITALIAN = 'it',
  ITALIAN_ITALY = 'it-IT',
  SPANISH = 'es',
  SPANISH_MX = 'es-MX',
  SPANISH_SPAIN = 'es-ES'
}

export enum DISTANCE_UNITS {
  IMPERIAL = 'imperial',
  METRIC = 'metric'
}

export const l10n = {
  [LOCALES.ENGLISH]: {
    dateFormat: DATE_TIME_FORMATS.MMDDYYYY,
    distanceUnits: DISTANCE_UNITS.IMPERIAL,
    mapTileLg: 'en',
    timeFormat: DATE_TIME_FORMATS.TIME_12H,
    timeFormatWithSeconds: DATE_TIME_FORMATS.TIME_12H_SECONDS
  },
  [LOCALES.ENGLISH_CA]: {
    dateFormat: DATE_TIME_FORMATS.YYYYMMDD,
    distanceUnits: DISTANCE_UNITS.METRIC,
    mapTileLg: 'en',
    timeFormat: DATE_TIME_FORMATS.TIME_12H,
    timeFormatWithSeconds: DATE_TIME_FORMATS.TIME_12H_SECONDS
  },
  [LOCALES.ENGLISH_GB]: {
    dateFormat: DATE_TIME_FORMATS.DDMMYYYY,
    distanceUnits: DISTANCE_UNITS.IMPERIAL,
    mapTileLg: 'en',
    timeFormat: DATE_TIME_FORMATS.TIME_24H,
    timeFormatWithSeconds: DATE_TIME_FORMATS.TIME_24H_SECONDS
  },
  [LOCALES.ENGLISH_US]: {
    dateFormat: DATE_TIME_FORMATS.MMDDYYYY,
    distanceUnits: DISTANCE_UNITS.IMPERIAL,
    mapTileLg: 'en',
    timeFormat: DATE_TIME_FORMATS.TIME_12H,
    timeFormatWithSeconds: DATE_TIME_FORMATS.TIME_12H_SECONDS
  },
  [LOCALES.FRENCH]: {
    dateFormat: DATE_TIME_FORMATS.DDMMYYYY,
    distanceUnits: DISTANCE_UNITS.METRIC,
    mapTileLg: 'fr',
    timeFormat: DATE_TIME_FORMATS.TIME_24H,
    timeFormatWithSeconds: DATE_TIME_FORMATS.TIME_24H_SECONDS
  },
  [LOCALES.FRENCH_CA]: {
    dateFormat: DATE_TIME_FORMATS.YYYYMMDD,
    distanceUnits: DISTANCE_UNITS.METRIC,
    mapTileLg: 'fr',
    timeFormat: DATE_TIME_FORMATS.TIME_24H,
    timeFormatWithSeconds: DATE_TIME_FORMATS.TIME_12H_SECONDS
  },
  [LOCALES.FRENCH_FRANCE]: {
    dateFormat: DATE_TIME_FORMATS.DDMMYYYY,
    distanceUnits: DISTANCE_UNITS.METRIC,
    mapTileLg: 'fr',
    timeFormat: DATE_TIME_FORMATS.TIME_24H,
    timeFormatWithSeconds: DATE_TIME_FORMATS.TIME_24H_SECONDS
  },
  [LOCALES.GERMAN]: {
    dateFormat: DATE_TIME_FORMATS.DDMMYYYY,
    distanceUnits: DISTANCE_UNITS.METRIC,
    mapTileLg: 'de',
    timeFormat: DATE_TIME_FORMATS.TIME_24H,
    timeFormatWithSeconds: DATE_TIME_FORMATS.TIME_24H_SECONDS
  },
  [LOCALES.GERMAN_GERMANY]: {
    dateFormat: DATE_TIME_FORMATS.DDMMYYYY,
    distanceUnits: DISTANCE_UNITS.METRIC,
    mapTileLg: 'de',
    timeFormat: DATE_TIME_FORMATS.TIME_24H,
    timeFormatWithSeconds: DATE_TIME_FORMATS.TIME_24H_SECONDS
  },
  [LOCALES.ITALIAN]: {
    dateFormat: DATE_TIME_FORMATS.DDMMYYYY,
    distanceUnits: DISTANCE_UNITS.METRIC,
    mapTileLg: 'it',
    timeFormat: DATE_TIME_FORMATS.TIME_24H,
    timeFormatWithSeconds: DATE_TIME_FORMATS.TIME_24H_SECONDS
  },
  [LOCALES.ITALIAN_ITALY]: {
    dateFormat: DATE_TIME_FORMATS.DDMMYYYY,
    distanceUnits: DISTANCE_UNITS.METRIC,
    mapTileLg: 'it',
    timeFormat: DATE_TIME_FORMATS.TIME_24H,
    timeFormatWithSeconds: DATE_TIME_FORMATS.TIME_24H_SECONDS
  },
  [LOCALES.SPANISH]: {
    dateFormat: DATE_TIME_FORMATS.DDMMYYYY,
    distanceUnits: DISTANCE_UNITS.METRIC,
    mapTileLg: 'es',
    timeFormat: DATE_TIME_FORMATS.TIME_24H,
    timeFormatWithSeconds: DATE_TIME_FORMATS.TIME_24H_SECONDS
  },
  [LOCALES.SPANISH_MX]: {
    dateFormat: DATE_TIME_FORMATS.DDMMYYYY,
    distanceUnits: DISTANCE_UNITS.METRIC,
    mapTileLg: 'es',
    timeFormat: DATE_TIME_FORMATS.TIME_12H,
    timeFormatWithSeconds: DATE_TIME_FORMATS.TIME_12H_SECONDS
  },
  [LOCALES.SPANISH_SPAIN]: {
    dateFormat: DATE_TIME_FORMATS.DDMMYYYY,
    distanceUnits: DISTANCE_UNITS.METRIC,
    mapTileLg: 'es',
    timeFormat: DATE_TIME_FORMATS.TIME_24H,
    timeFormatWithSeconds: DATE_TIME_FORMATS.TIME_24H_SECONDS
  }
};

export const eul10n = {
  ...l10n,
  [LOCALES.ENGLISH]: {
    ...l10n[LOCALES.ENGLISH],
    dateFormat: DATE_TIME_FORMATS.DDMMYYYY,
    timeFormat: DATE_TIME_FORMATS.TIME_24H,
    timeFormatWithSeconds: DATE_TIME_FORMATS.TIME_24H_SECONDS
  }
};

export interface Environment {
  production: boolean;
  devTools: boolean;
  hmr: boolean;
  name: string;
  authEnv: string;
  appUrl: string;
  useSettingsApi: boolean;
  datadog: {
    applicationId: string;
    clientToken: string;
    tagEnvironment: string;
  };
  devCycle: {
    clientId: string;
  };
  here: {
    apiKeyV3: string;
  };
  openweathermap: {
    apiKey: string;
    opacity?: number;
  };
  googleTagManager?: {
    id: string;
    auth: string;
    environment: string;
  };
  auth: {
    clientID: string;
    domain: string;
    audience: string;
    applicationId: string;
    useRefreshTokens: boolean;
    maxAge: number;
  };
  locationApi: {
    url: string;
  };
  autocompleteApi: {
    url: string;
    pageSize: number;
  };
  apiBase: {
    url: string;
  };
  coreEntityApiBase: {
    url: string;
  };
  historicalApiBase: {
    url: string;
  };
  userPreferenceApiBase?: {
    url: string;
  };
  dataUpdateMessages: {
    updateInterval: number;
    idling: number;
    notIdling: number;
  };
  nearbyAssets: {
    searchRadiusMeters: number;
    assetsToReturn: number;
  };
  liveUpdate: {
    pageSize: number;
    pollingInterval: number;
    consecutiveFailureLimit: number;
    restartPollingDelay: number;
  };
  zTrakBatteryAlertPct: number;
  apiRequestPageSize: number;
  datadogSite: string;
  minimumTripMS: number;
  featureFlags: {
    assetIOs: boolean;
  };
  region: string;
  vdofleet?: {
    baseApiUrl: string;
    baseApiAudience: string;
  };
  gtcxUrl?: {
    activityFeedUrl: string;
    incidentInvestigation: string;
  };
  euUrl?: {
    vdoDashboardUrl: string;
  };
  i18n: {
    supportedLanguages: Array<string>;
    defaultLanguage: string;
  };
  l10n: Object;
  hamburgerMenuEnabled: boolean;
}
